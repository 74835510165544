// @mui
import { Stack, Typography ,Link} from '@mui/material';
//
// import { Link as RouterLink } from 'react-router-dom';

import { NavListProps } from '../nav';
import { StyledLink } from './styles';

// ----------------------------------------------------------------------

export default function ListDesktop({ list }: { list: any }) {
        /* eslint-disable */
  const { subheader, items } = list;

  return (
    <Stack spacing={1.5} alignItems="flex-start">
      <Typography variant="subtitle2">{subheader}</Typography>

      {items?.map((link :any) => (
        <Link key={link.title}
        component={StyledLink}
         variant="caption" sx={{ color: 'text.secondary' }}
          to={
            link.path === "/e-commerce/account/vouchers" ? 
            sessionStorage.getItem("x-at") === null ? "/auth/login-cover" :
            link.path : link.path
          
          }
         >
       
  
          {link.title}
        </Link>
      ))}
    </Stack>
  );
}
