import { Helmet } from 'react-helmet-async';
// sections
import ElearningTeachersView from 'src/sections/_e-learning/view/ElearningTeachersView';

// ----------------------------------------------------------------------

export default function ElearningTeachersPage() {
  return (
    <>
      <Helmet>
        <title>강사 | MASADA</title>
      </Helmet>

      <ElearningTeachersView />
    </>
  );
}
